import { initializeApp } from "firebase/app"
import { getMessaging } from "firebase/messaging"

//Firebase Config values imported from .env file
const firebaseConfig = {
    apiKey: "AIzaSyCUfwuCDmK7SvhKqg1tS00KEjrGTgA9-ek",

    authDomain: "cozystudy-8bdb1.firebaseapp.com",

    projectId: "cozystudy-8bdb1",

    storageBucket: "cozystudy-8bdb1.appspot.com",

    messagingSenderId: "205297337988",

    appId: "1:205297337988:web:b7f7220455daa1cd4c64d1",

    measurementId: "G-6F0C7QHBGX",
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)

// Messaging service
export const messaging = getMessaging(app)
