import moment from "moment/moment"
import HeatMapIcon from "./heatmapIcon"

const YearDashboard = ({ yearSelect, timePeriodMinutes, dashboardData }) => {
    // Initialize an empty array
    const resultArray = []

    // Iterate over the studyData and populate the resultArray
    dashboardData.forEach((obj) => {
        // Use moment to parse and format the date as YYYY-MM
        const yearMonth = moment(obj.study_date).format("YYYY-MM")
        const existingEntry = resultArray.find((entry) => entry.month === yearMonth)

        if (existingEntry) {
            existingEntry.totalMinutes += obj.minutes_studied
        } else {
            resultArray.push({
                month: yearMonth,
                totalMinutes: obj.minutes_studied,
            })
        }
    })

    return (
        <div>
            <div className="flex flex-row space-x-1">
                <p className="font-bold">Time Frame: </p>
                <p>{yearSelect}</p>
            </div>
            <div className="flex flex-row space-x-2">
                <div className="flex flex-col w-1/2">
                    <div className="p-1 bg-slate-100 rounded-md text-center">
                        <p className="font-semibold">
                            Total Minutes Studied: {timePeriodMinutes.current}
                        </p>
                    </div>
                </div>
                <div className="flex flex-row bg-slate-100 w-1/2 rounded-lg justify-center">
                    <div className="flex flex-row flex-wrap justify-start">
                        {resultArray.map((data) => (
                            <HeatMapIcon
                                key={data.month}
                                date={data.month}
                                minutesStudied={data.totalMinutes}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default YearDashboard
