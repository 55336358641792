import SettingsContent from "./settingsContent"
import { useContext } from "react"
import { IconContext } from "react-icons"
import {
    PiPlayDuotone,
    PiPauseDuotone,
    PiSkipForwardDuotone,
    PiArrowCounterClockwise,
} from "react-icons/pi"
import { getMessaging, getToken } from "firebase/messaging"

const TimeButton = ({ purpose, timeRemaining, setTimeRemaining, setTimerRunning }) => {
    const settings = useContext(SettingsContent)

    const timeButtonClicked = () => {
        settings.buttonClickAudio.current.pause()
        settings.buttonClickAudio.current.currentTime = 0
        settings.buttonClickAudio.current.play()
    }

    const requestPermission = async () => {
        // Requesting permission using Notification API
        const permission = await Notification.requestPermission()

        if (permission === "granted") {
            // Once authenticated, get the FCM token
            const messaging = getMessaging()
            //console.log(messaging)

            try {
                const token = await getToken(messaging, {
                    vapidKey:
                        "BEfoKL5wpJpnLJCqz1owrY005lwm6vWWep6gV0LFVixvr5t1ngizxrGDfy8DZ-tn01AuRoWjQtSy2Exabu2OaD4",
                })
                settings.pushToken.current = token
                //console.log("FCM Token:", token)
            } catch (error) {
                console.error("Error getting token:", error)
            }
        } else if (permission === "denied") {
            alert("You denied the notification")
        }
    }

    // Start Button Handling
    if (purpose === "Start") {
        return (
            <IconContext.Provider value={{ className: "timerButton" }}>
                <PiPlayDuotone
                    id="startButton"
                    onClick={() => {
                        if (settings.pushToken.current === null) {
                            requestPermission()
                        }

                        settings.timerWorker.current.postMessage({
                            timerRunning: true,
                            timeRemaining: timeRemaining,
                        })
                        timeButtonClicked()
                        setTimerRunning(true)
                    }}
                    className="size-8"
                />
            </IconContext.Provider>
        )
    }
    // Pause Button Handling
    else if (purpose === "Pause") {
        return (
            <IconContext.Provider value={{ className: "timerButton" }}>
                <PiPauseDuotone
                    id="pauseButton"
                    onClick={() => {
                        settings.timerWorker.current.postMessage({
                            timerRunning: false,
                            timeRemaining: null,
                        })
                        timeButtonClicked()
                        setTimerRunning(false)
                    }}
                    className="size-8"
                />
            </IconContext.Provider>
        )
    }
    // Pause Button Handling
    else if (purpose === "Restart") {
        return (
            <IconContext.Provider value={{ className: "timerButton" }}>
                <PiArrowCounterClockwise
                    id="restartButton"
                    onClick={() => {
                        settings.timerWorker.current.postMessage({
                            timerRunning: false,
                            timeRemaining: null,
                        })
                        timeButtonClicked()
                        setTimerRunning(false)

                        if (settings.cycleNumber % 8 === 0) {
                            setTimeRemaining(settings.longBreakTime)
                        } else if (settings.cycleNumber % 2 === 0) {
                            setTimeRemaining(settings.shortBreakTime)
                        } else {
                            setTimeRemaining(settings.workingTime)
                        }
                    }}
                    className="size-8"
                />
            </IconContext.Provider>
        )
    }
    // Skip Button Handling
    else {
        return (
            <IconContext.Provider value={{ className: "timerButton" }}>
                <PiSkipForwardDuotone
                    id="skipButton"
                    onClick={() => {
                        settings.timerWorker.current.postMessage({
                            timerRunning: false,
                            timeRemaining: null,
                        })
                        timeButtonClicked()
                        setTimerRunning(false)
                        settings.setCycleNumber(settings.cycleNumber + 1)
                    }}
                    className="size-8"
                />
            </IconContext.Provider>
        )
    }
}

export default TimeButton
