import { Tooltip } from "react-tooltip"
import { useContext } from "react"
import SettingsContent from "./settingsContent"

const HeatMapIcon = ({ date, minutesStudied }) => {
    const settings = useContext(SettingsContent)

    let displayDateNumber
    if (date.length > 7) {
        displayDateNumber = date.split("-")[2]
    } else {
        displayDateNumber = date.split("-")[1]
    }

    let className = "flex flex-col justify-center items-center p-1 m-1 rounded-md w-6 "

    // Deciding Bg color of styling
    if (minutesStudied === 0) {
        className += "bg-slate-200"
    } else if (minutesStudied === 0) {
        className += "bg-green-100"
    } else if (minutesStudied < 60) {
        className += "bg-green-200"
    } else if (minutesStudied < 120) {
        className += "bg-green-300"
    } else if (minutesStudied < 180) {
        className += "bg-green-400"
    } else {
        className += "bg-green-500"
    }

    // Deciding border syling
    if (date === settings.todayDate.current) {
        className += ` font-semibold`
    }

    return (
        <div>
            <div
                className={className}
                data-tooltip-id="my-tooltip"
                data-tooltip-content={`Minutes: ${minutesStudied}`}
            >
                <p>{displayDateNumber}</p>
            </div>
            <Tooltip id="my-tooltip" />
        </div>
    )
}

export default HeatMapIcon
