import HeatMapIcon from "./heatmapIcon"
import moment from "moment/moment"

const WeekDashboard = ({ timeWindowIndex, timePeriodMinutes, dashboardData }) => {
    const startDate = moment().isoWeek(timeWindowIndex).startOf("isoWeek").format("MM/DD")
    const endDate = moment().isoWeek(timeWindowIndex).endOf("isoweek").format("MM/DD")
    return (
        <div>
            <div className="flex flex-row space-x-1">
                <p className="font-bold">Time Frame: </p>
                <p>
                    {startDate} - {endDate}
                </p>
            </div>
            <div className="flex flex-row space-x-2">
                <div className="flex flex-col w-1/2">
                    <div className="p-1 bg-slate-100 rounded-md text-center">
                        <p className="font-semibold">
                            Total Minutes Studied: {timePeriodMinutes.current}
                        </p>
                    </div>
                </div>
                <div className="flex flex-row bg-slate-100 w-1/2 rounded-lg justify-center">
                    <div className="flex flex-row flex-wrap justify-start">
                        {dashboardData.map((data) => (
                            <HeatMapIcon
                                key={data.study_date}
                                date={data.study_date}
                                minutesStudied={data.minutes_studied}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WeekDashboard
