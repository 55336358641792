import React from "react"
import { PiX } from "react-icons/pi"
import { IconContext } from "react-icons"

const AuthMessage = ({ status, message, setShowMessage }) => {
    let styling = "flex flex-row rounded-lg p-3 items-center "

    if (status === "Success") {
        styling += "bg-green-200"
    } else {
        styling += "bg-red-200"
    }

    return (
        <div className={styling}>
            <p>
                {status}: {message}
            </p>
            <IconContext.Provider value={{ className: "closeButton" }}>
                <PiX
                    className="size-6 "
                    alt="Close Icon for Login Error"
                    onClick={() => {
                        setShowMessage(false)
                    }}
                />
            </IconContext.Provider>
        </div>
    )
}

export default AuthMessage
