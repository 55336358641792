import TimePeriodButton from "./timePeriodButton"
import moment from "moment/moment"
import { useContext, useEffect, useRef } from "react"
import SettingsContent from "./settingsContent"

const TimePeriodRow = ({
    timePeriod,
    setTimePeriod,
    setTimePeriodLimit,
    setTimeWindowIndex,
    setDashboardData,
    setYearSelect,
}) => {
    // Setting Context used
    const settings = useContext(SettingsContent)
    const availableYears = useRef([])

    // Use effect for creating range of years to select from
    useEffect(() => {
        availableYears.current = []
        for (let year = moment().year(); year >= 2000; year--) {
            availableYears.current.push(year)
        }
    }, [])

    // Handling the selected year
    const handleYearChange = (event) => {
        setYearSelect(event.target.value)
        if (timePeriod === "Year") {
            setTimeWindowIndex(event.target.value)
        }
    }

    return (
        <div className="flex flex-row space-x-1 md:space-x-2 items-center ">
            <TimePeriodButton
                isActive={timePeriod === "Week"}
                unit={"Week"}
                setTimePeriod={setTimePeriod}
                setTimePeriodLimit={setTimePeriodLimit}
                setTimeWindowIndex={setTimeWindowIndex}
                setDashboardData={setDashboardData}
            />
            <TimePeriodButton
                isActive={timePeriod === "Month"}
                unit={"Month"}
                setTimePeriod={setTimePeriod}
                setTimePeriodLimit={setTimePeriodLimit}
                setTimeWindowIndex={setTimeWindowIndex}
                setDashboardData={setDashboardData}
            />
            <TimePeriodButton
                isActive={timePeriod === "Year"}
                unit={"Year"}
                setTimePeriod={setTimePeriod}
                setTimePeriodLimit={setTimePeriodLimit}
                setTimeWindowIndex={setTimeWindowIndex}
                setDashboardData={setDashboardData}
            />
            <select
                className={`rounded-lg p-1 border-2 ${
                    settings.themes[settings.themeIndex].buttonBackgroundColor
                } ${settings.themes[settings.themeIndex].buttonBorderColor}`}
                onChange={handleYearChange}
            >
                {availableYears.current.map((year) => (
                    <option
                        key={year}
                        value={year}
                    >
                        {year}
                    </option>
                ))}
            </select>
        </div>
    )
}

export default TimePeriodRow
