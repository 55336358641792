import SettingsContent from "./settingsContent"
import AuthMessage from "./authMessage"
import { supabase } from "../scripts/supabaseClient"
import { useContext, useEffect, useState } from "react"

const AuthPage = () => {
    const settings = useContext(SettingsContent)
    const [isMounted, setIsMounted] = useState(false)
    const [loading, setLoading] = useState(false)
    const [showMessage, setShowMessage] = useState(false)
    const [authMessage, setAuthMessage] = useState("")
    const [authStatus, setAuthStatus] = useState("")
    const [forgottenPassword, setForgottenPassword] = useState(false)
    const [hasAccount, setHasAccount] = useState(true)

    // Login Handling
    const handleSignUp = async (event) => {
        event.preventDefault()

        setLoading(true)

        const { data, error } = await supabase.auth.signUp({
            email: event.target.email.value,
            password: event.target.password.value,
        })

        if (data) {
            setShowMessage(true)
            setAuthStatus("Success")
            setAuthMessage("Check your email for the login link!")
        }

        if (error) {
            setShowMessage(true)
            setAuthStatus("Failure")
            setAuthMessage(error.message)
        }

        setLoading(false)
    }

    // Handling Login
    const handleLogin = async (event) => {
        event.preventDefault()

        setLoading(true)

        const { data, error } = await supabase.auth.signInWithPassword({
            email: event.target.email.value,
            password: event.target.password.value,
        })

        if (data) {
            console.log(data)
            setShowMessage(true)
            setAuthStatus("Success")
            setAuthMessage("Login Complete")
        }

        if (error) {
            console.log(error)
            setShowMessage(true)
            setAuthStatus("Failure")
            setAuthMessage(error.message)
        }

        setLoading(false)
    }

    // Handling Signout
    const handleSignOut = async () => {
        const { data, error } = await supabase.auth.signOut()

        if (data) {
            console.log(data)
            setShowMessage(true)
            setAuthStatus("Success")
            setAuthMessage("SignOut Complete")
        }

        if (error) {
            console.log(error)
            setShowMessage(true)
            setAuthStatus("Failure")
            setAuthMessage("SignOut Failed")
        }
    }

    const handleForgotPassword = async (event) => {
        // Prevent the form from submitting
        event.preventDefault()

        // Change state or perform any other action
        const { data, error } = await supabase.auth.resetPasswordForEmail(
            event.target.email.value,
            { redirectTo: "https://www.cozystudy.xyz/update-password" }
        )

        if (data) {
            setShowMessage(true)
            setAuthStatus("Success")
            setAuthMessage("Check your email for the password reset link!")
        }

        if (error) {
            console.log(error)
            setShowMessage(true)
            setAuthStatus("Failure")
            setAuthMessage(error.message)
        }
    }

    // Trigger the slide-in animation on component mount
    useEffect(() => {
        // Delay to ensure the component mounts first, then triggers animation
        const timeout = setTimeout(() => {
            setIsMounted(true)
        }, 10) // Small delay, e.g., 10ms, to ensure transition triggers

        return () => {
            clearTimeout(timeout)
            setIsMounted(false)
        }
    }, [])

    return (
        <div className="w-screen h-screen bg-slate-300 bg-opacity-70 absolute flex flex-col items-center justify-center">
            <div
                className={`transform transition-transform duration-500 ease-out ${
                    isMounted ? "translate-y-0 opacity-100" : "-translate-y-full opacity-0"
                } bg-slate-200 w-4/5 h-fit flex flex-col shadow-md rounded-lg p-3 justify-center items-center space-x-2`}
                style={{
                    backgroundColor: `${settings.themes[settings.themeIndex].timerColor}`,
                }}
            >
                {showMessage ? (
                    <AuthMessage
                        status={authStatus}
                        message={authMessage}
                        setShowMessage={setShowMessage}
                    />
                ) : null}

                {settings.session ? (
                    <div className="flex flex-col items-center space-y-2">
                        <div>
                            <p>Signed In as:</p>
                            <p className="font-semibold">{settings.session.user.email}</p>
                        </div>
                        <button
                            className={`w-fit p-1 rounded-md hover:bg-slate-200 border-2 ${
                                settings.themes[settings.themeIndex].buttonBackgroundColor
                            } ${settings.themes[settings.themeIndex].buttonBorderColor}`}
                            onClick={() => {
                                handleSignOut()
                            }}
                        >
                            Log Out
                        </button>
                    </div>
                ) : null}

                {forgottenPassword && !settings.session ? (
                    <div className="w-1/2 h-full flex flex-col justify-center items-center space-y-1">
                        <p
                            className="text-xl font-semibold"
                            style={{
                                color: `${settings.themes[settings.themeIndex].timerTextColor}`,
                            }}
                        >
                            Reset Password
                        </p>

                        <form
                            className="form-widget flex flex-col items-center space-y-2"
                            onSubmit={handleForgotPassword}
                        >
                            <div className="flex flex-col space-y-2">
                                <div className="flex flex-col">
                                    <label className="text-md">Email</label>
                                    <input
                                        className="inputField rounded-md p-1"
                                        type="email"
                                        name="email"
                                        placeholder="Email"
                                        required={true}
                                    />
                                </div>
                            </div>
                            <div>
                                <button
                                    className={`w-fit p-1 rounded-md hover:bg-slate-200 border-2 ${
                                        settings.themes[settings.themeIndex].buttonBackgroundColor
                                    } ${settings.themes[settings.themeIndex].buttonBorderColor}`}
                                    disabled={loading}
                                >
                                    {loading ? <span>Loading</span> : <span>Reset Password</span>}
                                </button>
                            </div>
                        </form>
                    </div>
                ) : null}

                {hasAccount && !settings.session && !forgottenPassword ? (
                    <div className="w-1/2 h-full flex flex-col justify-center items-center space-y-1">
                        <p
                            className="text-xl font-semibold"
                            style={{
                                color: `${settings.themes[settings.themeIndex].timerTextColor}`,
                            }}
                        >
                            Login
                        </p>

                        <form
                            className="form-widget flex flex-col items-center space-y-2"
                            onSubmit={handleLogin}
                        >
                            <div className="flex flex-col space-y-2">
                                <div className="flex flex-col">
                                    <label className="text-md">Email</label>
                                    <input
                                        className="inputField rounded-md p-1"
                                        type="email"
                                        name="email"
                                        placeholder="Email"
                                        required={true}
                                    />
                                </div>
                                <div className="flex flex-col">
                                    <div className="flex flex-row">
                                        <label className="text-md">Password</label>
                                        <span className="flex grow"></span>
                                        <button
                                            className="text-xs hover:font-semibold"
                                            onClick={() => {
                                                setForgottenPassword(true)
                                            }}
                                        >
                                            Forgot Password?
                                        </button>
                                    </div>
                                    <input
                                        className="inputField rounded-md p-1"
                                        type="password"
                                        name="password"
                                        placeholder="Password"
                                        required={true}
                                    />
                                </div>
                            </div>
                            <div>
                                <button
                                    className={`w-fit p-1 rounded-md hover:bg-slate-200 border-2 ${
                                        settings.themes[settings.themeIndex].buttonBackgroundColor
                                    } ${settings.themes[settings.themeIndex].buttonBorderColor}`}
                                    disabled={loading}
                                >
                                    {loading ? <span>Loading</span> : <span>Login</span>}
                                </button>
                            </div>
                        </form>
                        <div className="flex flex-row items-center space-x-1">
                            <p className="text-sm">Don't have an account?</p>
                            <button
                                className="text-sm hover:font-semibold"
                                onClick={() => {
                                    setHasAccount(false)
                                }}
                            >
                                Sign Up
                            </button>
                        </div>
                    </div>
                ) : null}

                {!hasAccount && !settings.session && !forgottenPassword ? (
                    <div className="w-1/2 h-full flex flex-col justify-center items-center space-y-1">
                        <p
                            className="text-xl font-semibold"
                            style={{
                                color: `${settings.themes[settings.themeIndex].timerTextColor}`,
                            }}
                        >
                            Sign Up
                        </p>

                        <form
                            className="form-widget flex flex-col items-center space-y-2"
                            onSubmit={handleSignUp}
                        >
                            <div className="flex flex-col space-y-2">
                                <div className="flex flex-col">
                                    <label className="text-md">Email</label>
                                    <input
                                        className="inputField rounded-md p-1"
                                        type="email"
                                        name="email"
                                        placeholder="Email"
                                        required={true}
                                    />
                                </div>
                                <div className="flex flex-col">
                                    <div className="flex flex-row">
                                        <label className="text-md">Password</label>
                                        <span className="flex grow"></span>
                                    </div>
                                    <input
                                        className="inputField rounded-md p-1"
                                        type="password"
                                        name="password"
                                        placeholder="Password"
                                        required={true}
                                    />
                                </div>
                            </div>
                            <div>
                                <button
                                    className={`w-fit p-1 rounded-md hover:bg-slate-200 border-2 ${
                                        settings.themes[settings.themeIndex].buttonBackgroundColor
                                    } ${settings.themes[settings.themeIndex].buttonBorderColor}`}
                                    disabled={loading}
                                >
                                    {loading ? <span>Loading</span> : <span>Sign Up</span>}
                                </button>
                            </div>
                        </form>
                        <div className="flex flex-row items-center space-x-1">
                            <p className="text-sm">Have an account?</p>
                            <button
                                className="text-sm hover:font-semibold"
                                onClick={() => {
                                    setHasAccount(true)
                                }}
                            >
                                Login
                            </button>
                        </div>
                    </div>
                ) : null}
            </div>
        </div>
    )
}

export default AuthPage
