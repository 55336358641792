import React from "react"
import ReactDOM from "react-dom/client"
import "./index.css"
import App from "./App"
import AuthReset from "./components/authReset"

import { createBrowserRouter, RouterProvider } from "react-router-dom"

const router = createBrowserRouter([
    {
        path: "/",
        element: <App />,
    },
    {
        path: "/update-password",
        element: <AuthReset />,
    },
])

ReactDOM.createRoot(document.getElementById("root")).render(<RouterProvider router={router} />)
