import SettingsContent from "./settingsContent"
import { useContext } from "react"
import moment from "moment"

const TimePeriodButton = ({
    unit,
    isActive,
    setTimePeriod,
    setTimePeriodLimit,
    setTimeWindowIndex,
    setDashboardData,
}) => {
    // Setting Context used
    const settings = useContext(SettingsContent)

    // Classnames Styling
    let className = `rounded-lg p-1 border-2 hover:bg-slate-200 ${
        settings.themes[settings.themeIndex].buttonBackgroundColor
    } ${settings.themes[settings.themeIndex].buttonBorderColor}`

    // Active button morders
    if (isActive) {
        className = className.replace(
            `${settings.themes[settings.themeIndex].buttonBorderColor}`,
            "border-slate-500"
        )
    }

    if (unit === "Week") {
        return (
            <button
                className={className}
                onClick={() => {
                    setDashboardData([])
                    setTimePeriod(unit)
                    setTimePeriodLimit(moment().isoWeek())
                    setTimeWindowIndex(moment().isoWeek())
                }}
            >
                {unit}
            </button>
        )
    } else if (unit === "Month") {
        return (
            <button
                className={className}
                onClick={() => {
                    setDashboardData([])
                    setTimePeriod(unit)
                    setTimePeriodLimit(moment().month())
                    setTimeWindowIndex(moment().month())
                }}
            >
                {unit}
            </button>
        )
    } else {
        return (
            <button
                className={className}
                onClick={() => {
                    setDashboardData([])
                    setTimePeriod(unit)
                    setTimePeriodLimit(moment().year())
                }}
            >
                {unit}
            </button>
        )
    }
}

export default TimePeriodButton
