//import "../index.css";
import SettingsContent from "./settingsContent"
import {
    HiOutlinePlusCircle,
    HiOutlineMinusCircle,
    HiOutlineArrowCircleLeft,
    HiOutlineArrowCircleRight,
} from "react-icons/hi"

import { useContext, useEffect, useState } from "react"

const Settings = () => {
    const settings = useContext(SettingsContent)
    const [isMounted, setIsMounted] = useState(false)

    const handleCheckboxChange = () => {
        settings.setTabTimer(!settings.showTabTimer)
    }

    // Trigger the slide-in animation on component mount
    useEffect(() => {
        // Delay to ensure the component mounts first, then triggers animation
        const timeout = setTimeout(() => {
            setIsMounted(true)
        }, 10) // Small delay, e.g., 10ms, to ensure transition triggers

        return () => {
            clearTimeout(timeout)
            setIsMounted(false)
        }
    }, [])

    // Handling for when the user changes any of the settings
    useEffect(() => {
        console.log("Saving settings to local storage")
        localStorage.setItem("showTabTimer", JSON.stringify(settings.showTabTimer))
        localStorage.setItem("workingTime", JSON.stringify(settings.workingTime))
        localStorage.setItem("shortBreakTime", JSON.stringify(settings.shortBreakTime))
        localStorage.setItem("longBreakTime", JSON.stringify(settings.longBreakTime))
        localStorage.setItem("backgroundImageIndex", JSON.stringify(settings.backgroundImageIndex))
        localStorage.setItem("themeIndex", JSON.stringify(settings.themeIndex))
    }, [
        settings.showTabTimer,
        settings.workingTime,
        settings.shortBreakTime,
        settings.longBreakTime,
        settings.backgroundImageIndex,
        settings.themeIndex,
    ])

    // For Showing Timer in Tab Info
    useEffect(() => {
        let timeRemaining
        if (settings.showTabTimer === true) {
            if (settings.cycleNumber % 8 === 0) {
                timeRemaining = settings.longBreakTime
            } else if (settings.cycleNumber % 2 === 0) {
                timeRemaining = settings.shortBreakTime
            } else {
                timeRemaining = settings.workingTime
            }
            document.title = `${settings.formatTime(timeRemaining)} - CozyStudy`
        } else {
            document.title = "CozyStudy"
        }
    }, [settings, settings.showTabTimer])

    return (
        <div className="w-screen h-screen bg-slate-300 bg-opacity-70 absolute flex flex-col items-center justify-center">
            <div
                className={`transform transition-transform duration-500 ease-out ${
                    isMounted ? "translate-y-0 opacity-100" : "-translate-y-full opacity-0"
                } bg-slate-200 w-4/5 sm:w-1/2 h-fit flex flex-col shadow-md rounded-lg p-3 justify-center items-center space-y-2`}
                style={{
                    backgroundColor: `${settings.themes[settings.themeIndex].timerColor}`,
                }}
            >
                <h1 className="text-3xl font-semibold text-center select-none">User Settings:</h1>
                <div className="flex flex-row space-x-2 sm:text-2xl items-center justify-center w-full">
                    <HiOutlineMinusCircle
                        className="size-6 sm:mt-1 hover:stroke-slate-600"
                        onClick={() => {
                            if (settings.workingTime > 60) {
                                settings.setWorkingTime(settings.workingTime - 60)
                            }
                        }}
                    />
                    <p className="text-center align-middle select-none text-lg w-3/5 lg:w-2/5 3xl:w-1/5">
                        Working Minutes: {Math.floor(settings.workingTime / 60)}
                    </p>
                    <HiOutlinePlusCircle
                        className="size-6 sm:mt-1 hover:stroke-slate-600"
                        onClick={() => settings.setWorkingTime(settings.workingTime + 60)}
                    />
                </div>
                <div className="flex flex-row space-x-2 sm:text-2xl items-center justify-center  w-full">
                    <HiOutlineMinusCircle
                        className="size-6 sm:mt-1 hover:stroke-slate-600"
                        onClick={() => {
                            if (settings.shortBreakTime > 60) {
                                settings.setShortBreakTime(settings.shortBreakTime - 60)
                            }
                        }}
                    />
                    <p className="text-center align-middle select-none text-lg w-3/5 lg:w-2/5 3xl:w-1/5">
                        Short Break Minutes: {Math.floor(settings.shortBreakTime / 60)}
                    </p>
                    <HiOutlinePlusCircle
                        className="size-6 sm:mt-1 hover:stroke-slate-600"
                        onClick={() => settings.setShortBreakTime(settings.shortBreakTime + 60)}
                    />
                </div>
                <div className="flex flex-row space-x-2 sm:text-2xl items-center justify-center  w-full">
                    <HiOutlineMinusCircle
                        className="size-6 sm:mt-1 hover:stroke-slate-600"
                        onClick={() => {
                            if (settings.longBreakTime > 60) {
                                settings.setLongBreakTime(settings.longBreakTime - 60)
                            }
                        }}
                    />
                    <p className="text-center align-middle select-none text-lg w-3/5 lg:w-2/5 3xl:w-1/5">
                        Long Break Minutes: {Math.floor(settings.longBreakTime / 60)}
                    </p>
                    <HiOutlinePlusCircle
                        className="size-6 sm:mt-1 hover:stroke-slate-600"
                        onClick={() => settings.setLongBreakTime(settings.longBreakTime + 60)}
                    />
                </div>

                <div className="flex flex-row space-x-2 sm:text-2xl items-center justify-center w-full">
                    <HiOutlineArrowCircleLeft
                        className="size-6 sm:mt-1 hover:stroke-slate-600"
                        onClick={() => {
                            if (settings.backgroundImageIndex > 0) {
                                settings.setBackgroundImageIndex(settings.backgroundImageIndex - 1)
                            } else {
                                settings.setBackgroundImageIndex(settings.backgrounds.length - 1)
                            }
                        }}
                    />
                    <p className="text-center align-middle select-none text-lg w-3/5 lg:w-2/5 3xl:w-1/5">
                        Background:
                        {settings.backgrounds[settings.backgroundImageIndex].name}
                    </p>
                    <HiOutlineArrowCircleRight
                        className="size-6 sm:mt-1 hover:stroke-slate-600"
                        onClick={() => {
                            if (settings.backgroundImageIndex < settings.backgrounds.length - 1) {
                                settings.setBackgroundImageIndex(settings.backgroundImageIndex + 1)
                            } else {
                                settings.setBackgroundImageIndex(0)
                            }
                        }}
                    />
                </div>
                <div className="flex flex-row space-x-2 sm:text-2xl items-center justify-center w-full">
                    <HiOutlineArrowCircleLeft
                        className="size-6 sm:mt-1 hover:stroke-slate-600"
                        onClick={() => {
                            if (settings.themeIndex > 0) {
                                settings.setThemeIndex(settings.themeIndex - 1)
                            } else {
                                settings.setThemeIndex(settings.themes.length - 1)
                            }
                        }}
                    />
                    <p className="text-center align-middle select-none text-lg w-3/5 lg:w-2/5 3xl:w-1/5">
                        Theme:
                        {settings.themes[settings.themeIndex].name}
                    </p>
                    <HiOutlineArrowCircleRight
                        className="size-6 sm:mt-1 hover:stroke-slate-600"
                        onClick={() => {
                            if (settings.themeIndex < settings.themes.length - 1) {
                                settings.setThemeIndex(settings.themeIndex + 1)
                            } else {
                                settings.setThemeIndex(0)
                            }
                        }}
                    />
                </div>
                <div className="flex flex-row space-x-2 sm:text-2xl items-center justify-center w-3/5 lg:w-2/5 3xl:w-1/5">
                    <p className="text-center align-middle select-none text-lg ">
                        Show Timer in Tab
                    </p>
                    <label>
                        <input
                            type="checkbox"
                            checked={settings.showTabTimer}
                            onChange={handleCheckboxChange}
                        />
                    </label>
                </div>
            </div>
        </div>
    )
}

export default Settings
