import { supabase } from "../scripts/supabaseClient"
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import AuthMessage from "./authMessage"
import NightMoutains from "../assets/backgrounds/NightMountains.jpg"
import NightMoutainsSmall from "../assets/backgrounds/NightMountainsSmall.jpg"

const AuthReset = () => {
    //const settings = useContext(SettingsContent)
    const [isMounted, setIsMounted] = useState(false)
    const [imageLoaded, setImageLoaded] = useState(false)
    const [loading, setLoading] = useState(false)
    const [session, SetSession] = useState(null)
    const [showMessage, setShowMessage] = useState(false)
    const [authMessage, setAuthMessage] = useState("")
    const [authStatus, setAuthStatus] = useState("")

    const navigate = useNavigate()

    // Image Loading Handler
    useEffect(() => {
        setImageLoaded(false)
        const img = new Image()
        img.src = NightMoutains
        img.alt = "Night Mountains"
        img.onload = () => {
            setImageLoaded(true)
        }

        return () => {
            setImageLoaded(false)
        }
    }, [])

    // Handling Password Reset
    const handleReset = async (event) => {
        event.preventDefault()

        setLoading(true)

        const { data, error } = await supabase.auth.updateUser({
            password: event.target.password.value,
        })

        if (data) {
            console.log(data)
            setShowMessage(true)
            setAuthStatus("Success")
            setAuthMessage("Password reset")
        }

        if (error) {
            console.log(error)
            setShowMessage(true)
            setAuthStatus("Failure")
            setAuthMessage(error.message)
        }

        setLoading(false)
    }

    useEffect(() => {
        supabase.auth.onAuthStateChange(async (event, session) => {
            if (event === "PASSWORD_RECOVERY") {
                SetSession(session)
            }
        })
    }, [])

    // Trigger the slide-in animation on component mount
    useEffect(() => {
        // Delay to ensure the component mounts first, then triggers animation
        const timeout = setTimeout(() => {
            setIsMounted(true)
        }, 10) // Small delay, e.g., 10ms, to ensure transition triggers

        return () => {
            clearTimeout(timeout)
            setIsMounted(false)
        }
    }, [])

    return (
        <div
            className="w-screen h-screen bg-no-repeat bg-cover bg-center absolute flex flex-col items-center justify-center"
            style={{
                backgroundImage: imageLoaded
                    ? `url(${NightMoutains})`
                    : `url(${NightMoutainsSmall}`,
                backdropFilter: imageLoaded ? "none" : `blur(16px)`,
            }}
        >
            <div
                className={`transform transition-transform duration-500 ease-out ${
                    isMounted ? "translate-y-0 opacity-100" : "-translate-y-full opacity-0"
                } bg-slate-300 w-4/5 h-fit flex flex-col shadow-md rounded-lg p-3 justify-center items-center opacity-95 space-x-2 space-y-2`}
            >
                {showMessage ? (
                    <AuthMessage
                        status={authStatus}
                        message={authMessage}
                        setShowMessage={setShowMessage}
                    />
                ) : null}

                <p className="text-3xl">CozyStudy Password Reset</p>

                {authStatus === "Success" && session ? (
                    <button
                        className="bg-slate-100 w-fit py-1 px-2 rounded-md hover:bg-slate-200 border-2"
                        onClick={() => {
                            navigate("/")
                        }}
                    >
                        Return to CozyStudy
                    </button>
                ) : null}

                {authStatus !== "Success" && session ? (
                    <form
                        className="form-widget flex flex-col items-center space-y-2"
                        onSubmit={handleReset}
                    >
                        <p className="text-lg">Enter a new password for {session.user.email}</p>
                        <div className="flex flex-col space-y-2">
                            <input
                                className="inputField rounded-md p-1"
                                type="password"
                                name="password"
                                placeholder="New Password"
                                required={true}
                            />
                        </div>
                        <button
                            className={"bg-slate-100 w-fit py-1 px-2 rounded-md hover:bg-slate-200"}
                            disabled={loading}
                        >
                            {loading ? <span>Loading</span> : <span>Reset Password</span>}
                        </button>
                    </form>
                ) : null}

                {!session ? (
                    <div className="flex flex-col items-center space-y-3">
                        <div className="w-4/5">
                            <p>Uh-oh! Looks like you didn't click the "forget password" option.</p>
                            <p>
                                Please navigate to CozyStudy and click "forget password" in the user
                                account page.
                            </p>
                        </div>

                        <button
                            className="bg-slate-100 w-fit py-1 px-2 rounded-md hover:bg-slate-200 border-2"
                            onClick={() => {
                                navigate("/")
                            }}
                        >
                            Return to CozyStudy
                        </button>
                    </div>
                ) : null}
            </div>
        </div>
    )
}

export default AuthReset
