import { useState, useEffect, useContext, useRef } from "react"
import { supabase } from "../scripts/supabaseClient.js"
import TimeDisplay from "./timeDisplay"
import ProgressBar from "./progressBar"
import TimeButton from "./timeButton"
import SettingsContent from "./settingsContent"
import SoundButtonRow from "./soundButtonRow"

function Timer() {
    const settings = useContext(SettingsContent)
    const [isMounted, setIsMounted] = useState(false)
    const [timeRemaining, setTimeRemaining] = useState(settings.workingTime)
    const [progressBarValue, setProgressBarValue] = useState(0)
    const progressBarTotalRef = useRef(settings.workingTime)
    const timeRemainingRef = useRef(timeRemaining)

    const sendStudyData = async (inputData) => {
        try {
            const { data, error } = await supabase
                .from("study_sessions")
                .upsert(
                    {
                        user_id: inputData.user_id,
                        study_date: inputData.study_date,
                        minutes_studied: inputData.minutes_studied,
                    },
                    { onConflict: ["user_id", "study_date"] }
                )
                .select()

            if (data) {
                console.log("Data Sent Success")
            }

            if (error) {
                console.log(error)
            }
        } catch (error) {
            console.error("Error sending notification:", error)
        }
    }

    const sendPushNotification = async (pushToken, cycleNumber) => {
        try {
            const response = await fetch("https://cozystudy-server.vercel.app/send-notification", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    token: `${pushToken.current}`,
                    cycleNumber: cycleNumber,
                }),
            })

            if (response.ok) {
                console.log("Notification sent!")
            } else {
                console.error("Failed to send notification")
            }
        } catch (error) {
            console.error("Error sending notification:", error)
        }
    }

    // Trigger the slide-in animation on component mount
    useEffect(() => {
        // Delay to ensure the component mounts first, then triggers animation
        const timeout = setTimeout(() => {
            setIsMounted(true)
        }, 10) // Small delay, e.g., 10ms, to ensure transition triggers

        return () => {
            clearTimeout(timeout)
            setIsMounted(false)
        }
    }, [])

    useEffect(() => {
        if (!settings.timerWorker.current) {
            return
        }

        settings.timerWorker.current.onmessage = (e) => {
            if (e.data.timeRemaining === 0) {
                settings.setTimerRunning(false)
                if (settings.cycleNumber % 2 === 0) {
                    settings.breakFinishAudio.current.volume = 0.5
                    settings.breakFinishAudio.current.play()
                } else {
                    settings.workFinishAudio.current.volume = 0.5
                    settings.workFinishAudio.current.play()

                    // Local Storage Completion
                    settings.workingTimeCompleted.current += Math.floor(settings.workingTime / 60)
                    settings.workingCyclesCompleted.current += 1

                    // Handling Push
                    if (settings.session) {
                        // Try to send data to backend
                        const studyData = {
                            user_id: settings.session.user.email,
                            study_date: settings.todayDate.current,
                            minutes_studied: settings.workingTimeCompleted.current,
                        }
                        sendStudyData(studyData)
                    }

                    if (settings.pushToken) {
                        sendPushNotification(settings.pushToken, settings.cycleNumber)
                    }
                }
                settings.setCycleNumber(settings.cycleNumber + 1)
            } else {
                timeRemainingRef.current = e.data.timeRemaining
                setTimeRemaining(timeRemainingRef.current)
            }
        }
    }, [settings])

    useEffect(() => {
        if (settings.showTabTimer === true) {
            document.title = `${settings.formatTime(timeRemaining)} - CozyStudy`
        } else {
            document.title = "CozyStudy"
        }
    }, [timeRemaining, settings])

    useEffect(() => {
        let calculatedProgressBarValue = (1 - timeRemaining / progressBarTotalRef.current) * 100
        setProgressBarValue(calculatedProgressBarValue)
    }, [timeRemaining, progressBarTotalRef])

    useEffect(() => {
        if (settings.cycleNumber % 8 === 0) {
            setTimeRemaining(settings.longBreakTime)
            progressBarTotalRef.current = settings.longBreakTime
        } else if (settings.cycleNumber % 2 === 0) {
            setTimeRemaining(settings.shortBreakTime)
            progressBarTotalRef.current = settings.shortBreakTime
        } else {
            setTimeRemaining(settings.workingTime)
            progressBarTotalRef.current = settings.workingTime
        }
    }, [
        settings.cycleNumber,
        settings.longBreakTime,
        settings.shortBreakTime,
        settings.workingTime,
    ])

    let cycleDisplay = Math.ceil(settings.cycleNumber / 2)

    return (
        <div
            className={`bg-slate-200 w-4/5 h-fit flex flex-col shadow-md rounded-lg mx-3 p-3 justify-center items-center duration-2000 ease-out ${
                isMounted ? "opacity-90" : "opacity-0"
            } `}
            style={{
                backgroundColor: `${settings.themes[settings.themeIndex].timerColor}`,
            }}
        >
            <TimeDisplay
                timeRemaining={timeRemaining}
                formatTime={settings.formatTime}
            />

            <ProgressBar progressBarValue={progressBarValue} />

            <div className="row flex flex-row justify-center items-center w-full space-x-1">
                {settings.timerRunning ? (
                    <TimeButton
                        purpose="Pause"
                        timeRemaining={timeRemaining}
                        setTimeRemaining={setTimeRemaining}
                        setTimerRunning={settings.setTimerRunning}
                    />
                ) : (
                    <TimeButton
                        purpose="Start"
                        timeRemaining={timeRemaining}
                        setTimeRemaining={setTimeRemaining}
                        setTimerRunning={settings.setTimerRunning}
                    />
                )}

                <TimeButton
                    purpose="Restart"
                    timeRemaining={timeRemaining}
                    setTimeRemaining={setTimeRemaining}
                    setTimerRunning={settings.setTimerRunning}
                />
                <TimeButton
                    purpose="Skip"
                    timeRemaining={timeRemaining}
                    setTimeRemaining={setTimeRemaining}
                    setTimerRunning={settings.setTimerRunning}
                />
            </div>
            <SoundButtonRow />
            <p className="sm:text-xl text-center select-none">Current Cycle: {cycleDisplay}</p>
        </div>
    )
}

export default Timer
